import { useQuery } from '@apollo/client';
import {
  parseNumber, isValidNumber, getNumberType, ParsedNumber
} from 'libphonenumber-js';
import { useState } from 'react';

import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { VALIDATE_CONTENT } from 'queries/shared/validateContent';
import { ValidateContentData, ValidateContentVars } from 'types/oneflare.com.au/validations';

export const isPhoneNumberValid = (value: string, type: string): boolean => {
  const number = parseNumber(`${value}`, 'AU');
  if (type) {
    return getNumberType(number as ParsedNumber) === type && isValidNumber(number as ParsedNumber);
  }
  return isValidNumber(number as ParsedNumber);
};

export const isNameValid = (value: string): boolean => {
  const rgExp = /^['A-Za-z-]+(\s(['A-Za-z-]+))*$/;
  return Boolean(value.trim().match(rgExp));
};

// This regex is needed to match BE validation, original
// regex (config.email_regexp) is from Site:
// site/config/initializers/devise.rb:168
export const isEmailValid = (value: string): boolean => {
  const emailRegex = (/^([\w%+.-]+)@([\w-]+\.)+(\w{2,})$/i);
  return Boolean(value.trim().match(emailRegex));
};

export const useIsContentValid = (pageName: string) => {
  const [content, setContent] = useState('');
  const { loading, data } = useQuery<
    ValidateContentData,
    ValidateContentVars
  >(VALIDATE_CONTENT, {
    variables: { content },
    onError: (err) => DataDogRumAgent.addRumError(err, `Oneflare | ${pageName} | VALIDATE_CONTENT query`)
  });
  const isContentValid = (value: string): Promise<boolean> => {
    setContent(value);
    return new Promise((resolve) => {
      if (!loading) resolve(data?.validateContent);
    });
  };
  return isContentValid;
};

/*
  Currency validation
  Accept commas and decimal
*/
export const isAmountValid = (amount: string) => /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/.test(amount);
