/* eslint-disable react-hooks/exhaustive-deps */
import type { UserAgent } from 'next-useragent';
import {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
  useMemo
} from 'react';
import scrollToComponent from 'react-scroll-to-component-ssr';
import { type DefaultTheme, ThemeContext } from 'styled-components';

import { PageContext, type PageContextModel } from '@context-providers';
import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { useThemeContext } from 'shared/components/HighOrderComponent/withThemeProvider/withThemeProvider';
import {
  type CloudFrontImageResizeOptions,
  getCloudFrontImageUrl
} from 'shared/utils/cloudFrontImageResize';
import { findAnchorIn } from 'shared/utils/helpers';
import { ThemeContextModel } from 'types/oneflare.com.au/themeContext';

// Returns current window size
export const useWindowResize = (defaultSize: number) => {
  const [viewportWidth, setViewportWidth] = useState(defaultSize);
  const updateWindowDimensions = useCallback(() => {
    setViewportWidth(window.innerWidth);
  }, []);
  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions);
    updateWindowDimensions();
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, [updateWindowDimensions]);
  return viewportWidth;
};

// Checks window size is greater than the provided breakpoint, returns boolean
export const useBreakpoint = (
  breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
  defaultSize = typeof window !== 'undefined' ? window.innerWidth : 0
) => {
  const viewportWidth = useWindowResize(defaultSize);
  const theme = useThemeContext();
  const breakpointValue = parseInt(theme.breakpoint[breakpoint].min, 10);
  const [isBreakpoint, setIsBreakpoint] = useState(
    viewportWidth >= breakpointValue
  );
  useEffect(() => {
    setIsBreakpoint(viewportWidth >= breakpointValue);
  }, [breakpoint, breakpointValue, viewportWidth]);
  return isBreakpoint;
};

export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef(null);
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  // Set up the interval.
  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return savedCallback.current;
  }, [delay]);
};

export const useClock = () => {
  const [clock, setClock] = useState<Date>(new Date());
  useInterval(() => {
    setClock(new Date());
  }, 1000);
  return clock;
};

export const useGenerateAvatarBackgroundColor = (
  word: string
): string | boolean => {
  const themeContext = useContext(ThemeContext);
  const theme = themeContext as ThemeContextModel & DefaultTheme;
  const bgColorPalette = [
    theme.color.info,
    theme.color.secondaryLight,
    theme.color.tertiaryDark,
    theme.color.success,
    theme.color.danger
  ];

  if (!word) return false;

  // Convert string a = 1, b = 2 etc...
  // return sum of chars. Example 'ab' --> 3
  // Add all Char number together, any NaN values default color to 0
  const sumCharNumbers =
    [...word].reduce((a, b) => a + b.toLowerCase().charCodeAt(0) - 96, 0) || 0;
  return bgColorPalette[sumCharNumbers % bgColorPalette.length];
};

export const useHandleMutationAlert = () => {
  const { AlertContainer } = useContext<PageContextModel>(PageContext);
  const handleAlert = useCallback(
    ({
      errors,
      datadogRumMsg,
      successMsg
    }: {
      errors?: any;
      datadogRumMsg?: string;
      successMsg?: string;
    }) => {
      if (errors) {
        const errorMsg =
          typeof errors === 'string' ? errors : errors?.[0]?.message;
        AlertContainer.current?.error({
          message: errorMsg || 'Something went wrong, please try again'
        });
        DataDogRumAgent.addRumError(errors, datadogRumMsg);
        return;
      }
      if (successMsg) {
        AlertContainer?.current?.success({
          message: successMsg
        });
      }
    },
    [AlertContainer]
  );
  return [handleAlert];
};

type GetCloudFrontImageUrl = (options: {
  key: string;
  bucket?: string;
  dynamicEdits?: CloudFrontImageResizeOptions['edits'];
}) => string;
type UseCloudFrontImageResizeURL = (options: {
  defaultBucket: string;
  defaultEdits?: CloudFrontImageResizeOptions['edits'];
}) => [GetCloudFrontImageUrl];

export const useCloudFrontImageResizeURL: UseCloudFrontImageResizeURL = ({
  defaultBucket,
  defaultEdits = {}
}) => {
  const getCloudFrontImage = useCallback(
    ({ key, bucket = defaultBucket, dynamicEdits = {} }) =>
      getCloudFrontImageUrl({
        key,
        bucket,
        edits: { ...defaultEdits, ...dynamicEdits }
      }),
    [defaultBucket, defaultEdits]
  );
  return [getCloudFrontImage];
};

const LOCAL_STORAGE_CHANGE = 'LOCAL_STORAGE_CHANGE';

interface LocalStorageChangeEvent {
  subscriptionId: string;
}

const dispatchStorageChange = (subscriptionId: string) => {
  const event = new CustomEvent<LocalStorageChangeEvent>(LOCAL_STORAGE_CHANGE, {
    detail: { subscriptionId }
  });
  window.dispatchEvent(event);
};

const getLocalStorageValue = (key: string, fallbackValue: any) => {
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : fallbackValue;
  } catch (error) {
    DataDogRumAgent.addRumError(error, `useLocalStorage | getItem | ${key}`);
    return fallbackValue;
  }
};

/**
 * Hook for persistent localStorage state with optional cross-component synchronization
 * @param key - localStorage key
 * @param initialValue - default value if nothing in localStorage
 * @param subscriptionId - optional ID to enable pub/sub sync across components
 */
export function useLocalStorage<T>(
  key: string,
  initialValue: T,
  subscriptionId?: string
): [T, (value: T | ((val: T) => T)) => void] {
  // State used to trigger re-renders of listening components
  const [state, setState] = useState<T>(() =>
    getLocalStorageValue(key, initialValue)
  );

  // Subscribe to changes in localStorage if subscriptionId is provided
  useEffect(() => {
    if (!subscriptionId) return;

    const handleStorageChange = ({
      detail: { subscriptionId: eventId }
    }: CustomEvent<LocalStorageChangeEvent>) => {
      if (eventId === subscriptionId) {
        setState(getLocalStorageValue(key, initialValue));
      }
    };

    window.addEventListener(
      LOCAL_STORAGE_CHANGE,
      handleStorageChange as EventListener
    );

    return () => {
      window.removeEventListener(
        LOCAL_STORAGE_CHANGE,
        handleStorageChange as EventListener
      );
    };
  }, [subscriptionId]);

  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      try {
        const valueToStore =
          value instanceof Function
            ? value(getLocalStorageValue(key, state))
            : value;

        window.localStorage.setItem(key, JSON.stringify(valueToStore));
        setState(valueToStore);

        // Dispatch event to trigger re-renders of listening components
        if (subscriptionId) {
          dispatchStorageChange(subscriptionId);
        }
      } catch (error) {
        DataDogRumAgent.addRumError(
          error,
          `useLocalStorage | setItem | ${key}`
        );
      }
    },
    [key, state, subscriptionId]
  );

  return [state, setValue];
}

export const useIsMounted = () => {
  const ref = useRef(true);
  useEffect(() => {
    ref.current = false;
  }, []);
  return ref.current;
};

export const useSsrDone = () => {
  const [ssrDone, setSsrDone] = useState(false);
  useEffect(() => {
    setSsrDone(true);
  }, []);

  return ssrDone;
};

// custom hook to simulate prevProps for componentDidUpdate
export const usePrevious = (value: any) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

// detect bot clientSide
export const useBotDetection = (
  ua: UserAgent
): {
  isBot: boolean;
  staticRender: boolean;
} => {
  const isBot = useMemo(() => ua?.isBot ?? false, [ua?.isBot]);
  const staticRender = useMemo(() => {
    const source = ua?.source;
    const botsWeCareAbout = ['google', 'bingbot', 'screaming frog seo'];
    for (let key = 0; key < botsWeCareAbout.length; key += 1) {
      if (
        typeof source !== 'undefined' &&
        source.toLowerCase().indexOf(botsWeCareAbout[key]) > -1
      ) {
        return true;
      }
    }
    return false;
  }, [ua?.source]);

  return { isBot, staticRender };
};

export const useScrollToRefComponent = (componentRef) => {
  const scrollToRef = useCallback(
    (refName) =>
      scrollToComponent(componentRef.current[refName], {
        offset: -90,
        align: 'top',
        duration: 500,
        ease: 'inOutCirc'
      }),
    [componentRef]
  );

  useEffect(() => {
    if (componentRef.current) {
      scrollToRef(findAnchorIn(window.location.href));
    }
  }, [componentRef, scrollToRef]);

  return scrollToRef;
};

export enum TOOLTIPS {
  EDIT_AVATAR = 'edit-avatar',
  BUSINESS_VERIFICATION = 'business-verification',
  LEAD_SETTINGS = 'lead-settings',
  REQUEST_REVIEWS = 'request-reviews',
}

export type TooltipKey = `${TOOLTIPS}`;

export interface TooltipState {
  actioned: boolean;
  dismissedAt: string | null;
  dismissedUntil: string | null;
}

export type TooltipActions = {
  onAction: () => void;
  onDismiss: (partialState?: Partial<TooltipState>) => void;
  setValue: (value: Partial<TooltipState>) => void;
};

export type TooltipStateStore = {
  [K in TooltipKey]?: TooltipState;
};

export const tooltipInitialState: TooltipState = {
  actioned: false,
  dismissedAt: null,
  dismissedUntil: null
};

const initialTooltipStoreState: TooltipStateStore = {};

/**
 * Hook to manage tooltip state with persistence across sessions.
 *
 * Provides functionality to:
 * - Track if a tooltip has been actioned
 * - Dismiss tooltips temporarily or permanently
 * - Set custom dismissal periods
 * - Automatically cleanup expired dismissals
 *
 * @param businessId - Unique identifier for the business context
 * @param tooltipKey - Key identifying the specific tooltip (from TOOLTIPS enum)
 *
 * @returns [state, actions]
 * - state: Current tooltip state (actioned, dismissedAt, dismissedUntil)
 * - actions:
 *   - onAction: Mark tooltip as actioned
 *   - onDismiss: Dismiss tooltip with optional state properties
 *   - setValue: Directly set tooltip state properties
 *
 * @example
 * ```tsx
 * const [tooltipState, { onAction, onDismiss }] = useTooltipState(123, TOOLTIPS.AVATAR);
 *
 * // Dismiss for 7 days
 * onDismiss({
 *   dismissedUntil: new Date(Date.now() + 7 * DAY_MS).toISOString()
 * });
 *
 * // Mark as actioned
 * onAction();
 * ```
 */
export function useTooltipState(
  businessId: number,
  tooltipKey: TooltipKey
): [TooltipState, TooltipActions] {
  const [tooltipState, update] = useLocalStorage<TooltipStateStore>(
    `tooltips-${businessId}`,
    initialTooltipStoreState,
    `tooltip-${businessId}-${tooltipKey}`
  );

  useEffect(() => {
    if (tooltipState?.[tooltipKey]?.dismissedUntil) {
      const dismissedUntilDate = new Date(
        tooltipState[tooltipKey].dismissedUntil
      );

      // Check and cleanup expired dismissedUntil
      if (dismissedUntilDate <= new Date()) {
        update({
          ...tooltipState,
          [tooltipKey]: {
            ...tooltipState[tooltipKey],
            dismissedUntil: null
          }
        });
      }
    }
  }, [tooltipState, tooltipKey, update]);

  useEffect(() => {
    if (tooltipState && !tooltipState[tooltipKey]) {
      update({ ...tooltipState, [tooltipKey]: tooltipInitialState });
    }
  }, [tooltipKey, tooltipState, update]);

  const actions: TooltipActions = useMemo(
    () => ({
      onAction: () =>
        update((store) => ({
          ...store,
          [tooltipKey]: {
            actioned: true,
            dismissedAt: null,
            dismissedUntil: null
          }
        })),
      onDismiss: (otherUpdates?: Partial<TooltipState>) =>
        update((store) => ({
          ...store,
          [tooltipKey]: {
            ...store[tooltipKey],
            dismissedAt: new Date().toISOString(),
            dismissedUntil: null,
            ...otherUpdates
          }
        })),
      setValue: (value: Partial<TooltipState>) =>
        update((store) => ({
          ...store,
          [tooltipKey]: { ...store[tooltipKey], ...value }
        }))
    }),
    [update, tooltipKey]
  );

  return [tooltipState?.[tooltipKey] || tooltipInitialState, actions];
}
