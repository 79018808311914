import 'isomorphic-fetch';
import { HttpLink } from '@apollo/client';

import { getGraphQlApi } from 'lib/utils/Environment';

const GRAPHQL_API = getGraphQlApi();
export const link = new HttpLink({
  fetch,
  credentials: 'include',
  uri: GRAPHQL_API,
  useGETForQueries: true
});
