import Tracker from '@oneflare/flarekit/lib/libs/Tracker/Tracker';
import isArray from 'lodash/isArray';
import type { BehaviorSubject } from 'rxjs';

import type { CreatePendingJobAttrs, Features, Id, JobFormTrackName, TrackerModel, UserAnswers } from 'types/oneflare.com.au/jobForm';

import {
  CATEGORY_QUESTION_ID,
  CONTACT_DETAILS_ID,
  SCHEDULER_TRACKING_EVENTS,
  DESCRIPTION_QUESTION_ID,
  EMAIL_QUESTION_ID,
  LOCATION_QUESTION_ID,
  MOBILE_VERIFICATION_ID,
  MOBILE_VERIFICATION_TRACKING_EVENTS,
  DATE_PICKER_TRACKING_EVENTS,
  ATTACHMENTS_TRACKING_EVENTS,
  JOB_FORM_INTERACTION_EVENTS
} from './constants';
import experimentsHelper from './experimentsHelper';

type BuildTracker =  {
  answerValue: string;
  categoryId: Id;
  formStep: string;
  jobId: number;
  label: string;
  locationId: number;
  originalReferer: string;
  questionId: number;
  serviceTypeId: Array<number>;
};

type GetLabelForTracking = {
  clusteredUserAnswers?: UserAnswers;
  clusterQuestion?: boolean;
  clusterType?: string;
  isJobFormAnswer?: boolean;
  questionId: Id;
  userAnswer?: any;
};

type TrackFormLanding = (args: {
  categoryId: Id;
  locationId?: number
}) => void;

type TrackFormStart = (args: {
  categoryId: Id;
  locationId: Id;
  questionId: Id;
}) => void;

type TrackJobFormAnswer = (args: {
  answerValue: string;
  categoryId: Id;
  clusteredUserAnswers: UserAnswers;
  clusterQuestion: boolean;
  clusterType: string;
  descriptionQuestionId: string;
  locationId: Id;
  questionId: Id;
  serviceTypeId: Array<number>;
  step: number;
  userAnswer: any;
}) => void;

type TrackJobFormContinue = (args: {
  questionId: Id;
  step: number;
  userAnswers: BehaviorSubject<UserAnswers>;
}) => void

type TrackJobFormComplete = (args: {
  categoryId: Id;
  createPendingJobAttrs: CreatePendingJobAttrs;
  jobId: number;
  locationId: Id;
  questionId: Id;
  step: number;
}) => void;

type TrackAttachment = (args: {
  questionId: Id;
}) => void;

class TrackingHelper {
  private jobFormTracker: TrackerModel;

  constructor(args: BuildTracker){
    this.jobFormTracker = this.buildTracker(args);
  }

  private formatStepForTracking = (step: number): string => {
    return (step + 2).toString().padStart(2, '0');
  };

  private createTrackingLabel = (
    clusterType: string,
    questionId: Id,
    userAnswer: any,
    clusteredUserAnswers: UserAnswers
  ): string => {
    if (clusterType === 'number plate') {
      // e.g. "64:Bynumberplate|2:NSW|4:TEST00" or "64:Bymakeandmodel|65:Toyota|66:Camry|67:2018"
      const parentAnswerString = `${questionId}:${userAnswer.value.replace(/\s/g, '')}`;
      const clusteredAnswersString = Object.entries(clusteredUserAnswers)
        .reduce((prev, current) => {
          const [id, { inputType, userAnswer }] = current;
          return `${prev}|${id}:${inputType === 'Textfield' ? userAnswer : userAnswer.value}`;
        }, '');
      return `${parentAnswerString}${clusteredAnswersString}`;
    }
    return '';
  };

  private getQuestionIdForTracking = (questionId: Id): number => {
    switch (questionId) {
      case CATEGORY_QUESTION_ID:
        return -1;
      case DESCRIPTION_QUESTION_ID:
        return 6;
      case EMAIL_QUESTION_ID:
        return 3;
      case CONTACT_DETAILS_ID:
        return 4;
      case MOBILE_VERIFICATION_ID:
        return 9;
      default:
        return Number(questionId);
    }
  };

  private getAnswerValueForTracking = (
    answerValue: string | Array<any>,
    questionId: Id,
    descriptionQuestionId: string
  ): string => {
    if (questionId === descriptionQuestionId) return ''; // Description question is not tracked
    return (isArray(answerValue)
      ? (answerValue as Array<any>).join(',').replaceAll(' ', '')
      : (((answerValue as string) ?? '').replaceAll(' ', '')));
  };

  private getLabelForTracking = ({
    clusteredUserAnswers,
    clusterQuestion,
    clusterType,
    isJobFormAnswer = false,
    questionId,
    userAnswer
  }: GetLabelForTracking): string => {
    switch (true) {
      case questionId === DESCRIPTION_QUESTION_ID:
        return 'anyfurtherdetails';
      case questionId === EMAIL_QUESTION_ID:
        return 'email';
      case questionId === CONTACT_DETAILS_ID:
        return 'name/phone';
      case questionId === MOBILE_VERIFICATION_ID:
        return 'enteryourcode';
      case isJobFormAnswer && clusterQuestion:
        return this.createTrackingLabel(clusterType, questionId, userAnswer, clusteredUserAnswers);
      default:
        return '';
    }
  };

  private buildTracker = ({
    answerValue,
    categoryId,
    formStep,
    jobId,
    label,
    locationId,
    originalReferer,
    questionId,
    serviceTypeId
  }: BuildTracker): TrackerModel => {
    const form_name = originalReferer;
    const tracks = {
      // #region - interaction
      ...JOB_FORM_INTERACTION_EVENTS({form_name, categoryId: +categoryId, locationId, questionId, serviceTypeId, formStep, jobId, label, answerValue}),
      // #endregion - interaction
      // #region - attachments
      ...ATTACHMENTS_TRACKING_EVENTS(questionId),
      // #endregion - attachments
      // #region - datepicker
      ...DATE_PICKER_TRACKING_EVENTS,
      // #endregion - datepicker
      // #region - scheduler
      ...SCHEDULER_TRACKING_EVENTS,
      // #endregion - scheduler
      // #region - mobile verification
      ...MOBILE_VERIFICATION_TRACKING_EVENTS
      // #endregion - mobile verification
      // #endregion - interaction
    };

    Tracker.setTracks(tracks);
    return Tracker as unknown as TrackerModel;
  };

  trackJobFormLanding: TrackFormLanding = ({ categoryId, locationId }) => {
    this.jobFormTracker?.interaction('jobFormLanding', {
      category_id: Number(categoryId) || 0,
      location_id: Number(locationId) || 0,
      question_id: 0,
      value: 0
    });
  };

  trackInteraction(eventName: JobFormTrackName, payload?: Record<string, any>) {
    this.jobFormTracker?.interaction(eventName, payload);
  }

  trackJobFormStart: TrackFormStart = ({ categoryId, locationId, questionId }) => {
    const questionIdForTracking = this.getQuestionIdForTracking(questionId);
    this.jobFormTracker.interaction('jobFormStart', {
      question_id: questionIdForTracking,
      category_id: Number(categoryId),
      location_id: Number(locationId) || 0,
      value: questionIdForTracking
    });
  };

  trackJobFormAnswer: TrackJobFormAnswer = ({
    answerValue,
    categoryId,
    clusteredUserAnswers,
    clusterQuestion,
    clusterType,
    descriptionQuestionId,
    locationId,
    questionId,
    serviceTypeId,
    step,
    userAnswer
  }) => {
    const questionIdForTracking = this.getQuestionIdForTracking(questionId);
    this.jobFormTracker.interaction('jobFormAnswer', {
      form_step: this.formatStepForTracking(step),
      question_id: questionIdForTracking,
      label: this.getLabelForTracking({
        clusteredUserAnswers,
        clusterQuestion,
        clusterType,
        isJobFormAnswer: true,
        questionId: questionIdForTracking,
        userAnswer
      }),
      category_id: Number(categoryId),
      location_id: Number(locationId) || 0,
      service_type_id: serviceTypeId,
      value: questionIdForTracking,
      answer_value: this.getAnswerValueForTracking(answerValue, questionId, descriptionQuestionId)
    });
  };

  trackJobFormContinue: TrackJobFormContinue = ({ questionId, step, userAnswers }) => {
    const questionIdForTracking = this.getQuestionIdForTracking(questionId);
    this.jobFormTracker.interaction('jobFormContinue', {
      form_step: this.formatStepForTracking(step),
      question_id: questionIdForTracking,
      label: this.getLabelForTracking({ questionId }),
      category_id: Number(userAnswers.value[CATEGORY_QUESTION_ID].userAnswer.value),
      location_id: Number(userAnswers.value[LOCATION_QUESTION_ID].userAnswer.value) || 0,
      value: questionIdForTracking
    });
  };

  trackJobFormComplete: TrackJobFormComplete = ({
    categoryId,
    createPendingJobAttrs,
    jobId,
    locationId,
    questionId,
    step
  }) => {
    this.jobFormTracker.interaction('jobFormComplete', {
      form_step: this.formatStepForTracking(step),
      category_id: Number(categoryId),
      location_id: Number(locationId),
      service_type_id: createPendingJobAttrs?.jobData.serviceTypeIds.map((item) => Number(item)),
      job_id: jobId,
      value: this.getQuestionIdForTracking(questionId)
    });
  };

  trackAddAttachment: TrackAttachment = ({ questionId }) => {
    this.jobFormTracker.interaction(
      'addAttachment',
      { value: this.getQuestionIdForTracking(questionId) }
    );
  };

  trackRemoveAttachment: TrackAttachment = ({  questionId }) => {
    this.jobFormTracker.interaction(
      'removeAttachment',
      { value: this.getQuestionIdForTracking(questionId) }
    );
  };

  static getGAClientId = () => {
    let id;
    try {
      id = window.ga?.getAll()[0].get('clientId');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(err);
    }
    return id;
  };

  /**
   * Get the Snowplow uid
   * @return {string|undefined} uid
    */
  static getSnowplowUid = (_cookieName) => {
    const cookieName = _cookieName || '_sp_';
    const matcher = new RegExp(`${cookieName}id\\.[a-f0-9]+=([^;]+);?`);
    const match = document.cookie.match(matcher);
    if (match && match[1]) {
      return match[1].split('.')[0];
    }
    return undefined;
  };

  // Experiments tracking
  trackExperiments: (...args: [
    categoryId: Id,
    features: BehaviorSubject<Features>
  ]) => void = (categoryId, features) => {
    Object.keys(features.value).forEach((name) => {
      const feature = features.value[name];
      const featured = experimentsHelper.featured(name, +categoryId, features);
      if (feature.error
        || ('only' in featured && !featured.only)
        || ('skip' in featured && featured.skip)
      ) return;
      Tracker.dataLayer.push({
        event: 'landings',
        action: 'landing',
        category: 'internal_ab_testing',
        label: feature.label,
        value: featured.featured ? 2 : 1 // 1 = control, 2 = variation 1
      });
    });
  };
}

export default TrackingHelper;
