import { useMutation } from '@apollo/client';
import { FunctionComponent, PropsWithChildren, useState } from 'react';

import { DISMISS_UNVERIFIED_BANNER } from 'mutations/shared/businessVerification';
import { useHandleMutationAlert } from 'shared/utils/hooks';

import {
  NotificationBannerStyled,
  SuccessIconStyled,
  WarningIconStyled,
  InformationIconStyled,
  CloseIconStyled,
  AlertIconStyled
} from './styled/NotificationBannerStyled';
import { NOTIFICATION_STATUS, mutationMap } from './utils';

type NotificationStatus = typeof NOTIFICATION_STATUS[keyof typeof NOTIFICATION_STATUS];

type NotificationBanner = {
  action?: string;
  className?: string;
  closable?: boolean;
  status: NotificationStatus;
}

const NotificationBanner: FunctionComponent<PropsWithChildren<NotificationBanner>> = ({
  children,
  status = NOTIFICATION_STATUS.ERROR,
  className = '',
  closable = false,
  action = 'dismissUnverifiedBanner'
}) => {
  const [display, setDisplay] = useState('flex');

  const [dismissBanner] = useMutation(
    mutationMap[action] || DISMISS_UNVERIFIED_BANNER
  );
  const [handleAlert] = useHandleMutationAlert();

  const Icon = {
    [NOTIFICATION_STATUS.SUCCESS]: <SuccessIconStyled />,
    [NOTIFICATION_STATUS.WARNING]: <WarningIconStyled />,
    [NOTIFICATION_STATUS.INFO]: <InformationIconStyled />,
    [NOTIFICATION_STATUS.ALERT]: <AlertIconStyled />
  };

  const handleCloseClick = async () => {
    setDisplay('none');

    if (action) {
      const { errors } = await dismissBanner();
      handleAlert({
        errors,
        datadogRumMsg: 'Oneflare | Shared | DISMISS_UNVERIFIED_BANNER mutation'
      });
    }
  };

  return (
    <NotificationBannerStyled className={className} $display={display} $status={status}>
      {Icon[status]}
      {children}
      {closable && (
        <div onClick={handleCloseClick} aria-hidden="true">
          <CloseIconStyled />
        </div>
      )}
    </NotificationBannerStyled>
  );
};

export default NotificationBanner;
