import { withTheme, createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html, body {
    box-sizing: border-box;
    font-family: ${({ theme }) => theme.font.primaryRegular};
    font-size: 14px;
    margin: 0;
    text-size-adjust: 100%;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  @media only percy {
    .e2e-hide {
      visibility: hidden;
    }

    #launcher {
      display: none;
    }
  }

  :root {
    --rt-opacity: 1;
  }
`;

export default withTheme(GlobalStyles);
