import { setContext } from '@apollo/client/link/context';
import axios from 'axios';
import { OperationDefinitionNode } from 'graphql';

import { getGraphQlApi, getGraphQlToken, getDomain } from 'lib/utils/Environment';

const GRAPHQL_API = getGraphQlApi();
const GRAPHQL_TOKEN = getGraphQlToken();
const DOMAIN = getDomain();
const GRAPHQL_CRSF_TOKEN_ENDPOINT = `${GRAPHQL_API}?query={csrfToken}`;

export const csrfTokenLink = setContext(async (operation, { headers }) => {
  // X-CSRF-Token header is strictly required for mutations
  // Return early and skip this fetch action if current operation
  // does not include any mutations
  const hasMutations = operation.query.definitions.filter(
    (definition: OperationDefinitionNode) => definition.operation === 'mutation'
  ).length;
  if (!hasMutations) {
    return {
      headers: { ...headers }
    };
  }

  try {
    const { data: { data: { csrfToken } } } = await axios.get(
      GRAPHQL_CRSF_TOKEN_ENDPOINT,
      {
        headers: {
          X_SCOPE_TOKEN: GRAPHQL_TOKEN,
          X_SCOPE_DOMAIN: DOMAIN,
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }
    );
    return {
      headers: {
        ...headers,
        'X-CSRF-TOKEN': csrfToken
      }
    };
  } catch (error) {
    console.error('Error fetching CSRF token:', error);
    return {
      headers: { ...headers }
    };
  }
});
