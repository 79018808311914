import isEmpty from 'lodash/isEmpty';
import { BehaviorSubject } from 'rxjs';

import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { Features } from 'types/oneflare.com.au/jobForm';

import { EXPERIMENTS } from './constants';

class ExperimentsHelper {
  static getFeatures = (): Features => {
    const querySearch = ExperimentsHelper.querySearch(window.location.search.substring(1).split('&'));
    const features = { ...EXPERIMENTS };
    Object.keys(features).forEach((name) => {
      if (name in querySearch) features[name].bucket = 100;
    });
    return features;
  };

  /**
   * Set a percentage bucket number for every user that loads the page
   */
  static getBucket = (name: string, features: BehaviorSubject<Features>): number => {
    const getBuckets = () => {
      const buckets = {};
      const featuresWithError = { ...features.value };
      Object.keys(featuresWithError).forEach((name) => {
        try {
          const random = Math.round(Math.random() * 100);
          const bucket = localStorage.getItem(`job-form-experiment-bucket-${name}`);
          if (!bucket) localStorage.setItem(`job-form-experiment-bucket-${name}`, String(random));
          buckets[name] = bucket ? Number(bucket) : random;
        } catch (error) {
          /**
           * Safari safe navigation do not allow localStorage space
           * console.error(error);
           */
          DataDogRumAgent.addRumError(error, 'Oneflare | ExperimentsHelper | getBucket');
          featuresWithError[name].error = error;
        }
      });
      features.next(featuresWithError);
      return buckets;
    };
    const bucket = getBuckets()[name];
    return bucket;
  };

  /**
   * Check if a component is featured by name
   */
  static featured = (name: string, categoryId: number, features: BehaviorSubject<Features>) => {
    const feature = features.value?.[name];
    const only = Array.isArray(feature.only) && feature.only.includes(categoryId);
    const skip = Array.isArray(feature.skip) && feature.skip.includes(categoryId);
    const bucket = ExperimentsHelper.getBucket(name, features) <= feature.bucket;
    switch (true) {
      case 'only' in feature && 'skip' in feature:
        return {
          featured: bucket && only && !skip,
          bucket,
          only,
          skip
        };
      case 'only' in feature && !('skip' in feature):
        return {
          featured: bucket && only,
          bucket,
          only
        };
      case 'skip' in feature && !('only' in feature):
        return {
          featured: bucket && !skip,
          bucket,
          skip
        };
      default:
        return { featured: bucket, bucket };
    }
  };

  private static querySearch = (params: Array<string>): Record<string, string> => {
    if (isEmpty(params)) return {};
    const newObject = {};
    params.forEach((item) => {
      const parameter = item.split('=', 2);
      if (parameter.length === 1) {
        newObject[parameter[0]] = '';
      } else {
        newObject[parameter[0]] = decodeURIComponent(parameter[1].replace(/\+/g, ' '));
      }
    });
    return newObject;
  };
}

export default ExperimentsHelper;
